<!-- eslint-disable max-len -->
<template>
  <!-- Rating 1 -->
  <svg
    v-if="rating === 1"
    :class="props.class ?? ''"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 180 180"
    style="enable-background: new 0 0 180 180"
    xml:space="preserve"
    stroke="currentColor"
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="M163.322,80.693c-1.611-1.076-2.79-2.614-3.301-4.867c-1.211-5.34,1.685-9.366,6.375-10.43
			c4.643-1.054,9.117,1.448,10.281,6.58c0.552,2.438,0.152,4.334-0.672,5.865l-3.448-1.636c0.402-0.702,0.834-1.922,0.508-3.361
			c-0.648-2.856-3.101-3.985-5.724-3.39c-2.647,0.601-4.309,2.856-3.698,5.55c0.306,1.347,1.141,2.231,1.902,2.791L163.322,80.693z"
        />
        <path
          d="M161.646,97.591l0.041-3.978l6.501,0.068l0.06-5.715l-6.502-0.068l0.042-3.977l16.478,0.172l-0.042,3.977l-6.191-0.064
			l-0.059,5.715l6.191,0.064l-0.041,3.978L161.646,97.591z"
        />
        <path
          d="M160.9,101.505l16.106,3.487l-1.653,7.634l-3.63-0.786l0.81-3.748l-2.489-0.538l-0.786,3.631l-3.585-0.777l0.787-3.63
			l-2.77-0.6l-0.827,3.816l-3.63-0.785L160.9,101.505z"
        />
        <path
          d="M162.706,119.027l-6.085-2.412l1.465-3.695l15.314,6.07l-2.913,7.347l-3.452-1.368l1.448-3.65l-2.368-0.939l-1.404,3.541
			l-3.408-1.351L162.706,119.027z"
        />
        <path
          d="M165.52,135.641c-2.942,4.589-7.287,5.761-11.635,2.973c-4.328-2.776-5.173-7.277-2.23-11.865l2.031-3.166l13.864,8.892
			L165.52,135.641z M154.484,128.816c-1.433,2.191-1.067,4.575,1.618,6.298c2.665,1.709,4.925,1.095,6.357-1.183L154.484,128.816z"
        />
        <path
          d="M153.21,145.376c0.737-0.396,1.692-0.499,2.544,0.264c0.94,0.842,1.099,2.198,0.162,3.245
			c-0.921,1.028-2.251,1.052-3.227,0.179c-0.497-0.445-0.812-0.95-1.106-1.693l-1.148-2.786l1.715-1.916L153.21,145.376z"
        />
        <path
          d="M137.953,164.476l-2.29-2.925l3.02-2.362l-1.571-2.007l-2.925,2.289l-2.259-2.888l2.925-2.289l-1.747-2.231l-3.075,2.406
			l-2.291-2.926l6.039-4.725c4.35-3.405,8.901-2.883,12.027,1.111c3.081,3.938,2.384,8.534-1.873,11.865L137.953,164.476z
			 M141.815,156.737c2.134-1.639,2.608-3.854,0.715-6.273c-1.878-2.399-4.243-2.485-6.292-0.853L141.815,156.737z"
        />
        <path
          d="M127.573,159.044l3.959,9.076l-3.644,1.589l-3.959-9.076c-0.742-1.701-2.062-2.527-3.852-1.747
			c-1.723,0.752-2.029,2.313-1.287,4.015l3.95,9.055l-3.644,1.589l-3.949-9.055c-1.588-3.642-0.665-7.239,3.479-9.048
			C122.881,153.586,125.927,155.269,127.573,159.044z"
        />
        <path
          d="M113.12,175.513l-4.386,0.755l-5.742-10.63l-1.858,11.938l-4.175,0.718l3.189-17.261l4.246-0.73L113.12,175.513z"
        />
        <path
          d="M82.445,168.946l-3.336-7.721l4.254,0.237l2.856,6.931l1.069,0.06l0.377-6.75l3.946,0.221l-0.919,16.447l-5.657-0.315
			c-3.447-0.192-5.083-2.072-4.908-5.186C80.23,171.04,81.068,169.68,82.445,168.946z M86.939,174.729l0.169-3.042l-1.378-0.077
			c-0.904-0.051-1.462,0.538-1.513,1.466c-0.051,0.902,0.463,1.527,1.343,1.577L86.939,174.729z"
        />
        <path
          d="M76.549,160.559L72.63,176.56l-7.584-1.857l0.883-3.608l3.723,0.912l0.606-2.474l-3.607-0.883l0.872-3.562l3.608,0.885
			l0.673-2.753l-3.792-0.929l0.884-3.607L76.549,160.559z"
        />
      </g>
      <g>
        <path
          d="M16.678,99.307c1.611,1.075,2.79,2.614,3.301,4.866c1.211,5.341-1.686,9.365-6.375,10.43
			c-4.644,1.053-9.118-1.448-10.281-6.58c-0.552-2.438-0.153-4.335,0.672-5.865l3.448,1.637c-0.402,0.701-0.834,1.922-0.508,3.361
			c0.648,2.856,3.102,3.984,5.725,3.389c2.647-0.6,4.309-2.855,3.698-5.55c-0.306-1.347-1.141-2.231-1.902-2.791L16.678,99.307z"
        />
        <path
          d="M18.355,82.409l-0.042,3.977l-6.501-0.067l-0.06,5.714l6.501,0.068l-0.041,3.978L1.734,95.906l0.042-3.977l6.191,0.064
			l0.059-5.716l-6.191-0.064l0.042-3.977L18.355,82.409z"
        />
        <path
          d="M19.1,78.495L3.002,75.016l1.649-7.63l3.629,0.784L7.47,71.914l2.489,0.538l0.785-3.628l3.583,0.774l-0.785,3.629
			l2.768,0.598l0.826-3.814l3.628,0.784L19.1,78.495z"
        />
        <path
          d="M17.294,60.972l6.086,2.412l-1.465,3.695L6.601,61.01l2.913-7.347l3.451,1.367l-1.447,3.652l2.368,0.938l1.404-3.54
			l3.407,1.35L17.294,60.972z"
        />
        <path
          d="M14.481,44.359c2.942-4.589,7.287-5.762,11.634-2.973c4.328,2.775,5.173,7.276,2.231,11.865l-2.031,3.165l-13.864-8.892
			L14.481,44.359z M25.516,51.183c1.434-2.191,1.067-4.575-1.618-6.298c-2.664-1.709-4.925-1.095-6.356,1.183L25.516,51.183z"
        />
        <path
          d="M26.79,34.624c-0.737,0.395-1.692,0.498-2.544-0.265c-0.94-0.842-1.099-2.198-0.162-3.245
			c0.92-1.029,2.251-1.052,3.227-0.178c0.497,0.444,0.812,0.95,1.106,1.692l1.148,2.786l-1.715,1.916L26.79,34.624z"
        />
        <path
          d="M42.047,15.523l2.289,2.926l-3.02,2.361l1.571,2.008l2.925-2.289l2.259,2.887l-2.924,2.29l1.746,2.231l3.075-2.407
			l2.29,2.926l-6.038,4.725c-4.35,3.405-8.9,2.884-12.027-1.111c-3.081-3.938-2.384-8.534,1.873-11.865L42.047,15.523z
			 M38.185,23.262c-2.135,1.639-2.608,3.854-0.715,6.273c1.877,2.399,4.243,2.485,6.291,0.853L38.185,23.262z"
        />
        <path
          d="M52.427,20.957l-3.959-9.076l3.644-1.589l3.958,9.075c0.743,1.702,2.063,2.527,3.853,1.748
			c1.723-0.752,2.029-2.314,1.286-4.017L57.26,8.046l3.643-1.59l3.951,9.055c1.588,3.643,0.665,7.24-3.48,9.049
			C57.119,26.414,54.073,24.73,52.427,20.957z"
        />
        <path
          d="M66.879,4.486l4.387-0.754l5.741,10.628l1.858-11.937l4.175-0.719l-3.188,17.263l-4.247,0.729L66.879,4.486z"
        />
        <path
          d="M97.555,11.054l3.337,7.722l-4.254-0.237l-2.856-6.932l-1.07-0.061l-0.376,6.751l-3.946-0.22l0.919-16.448l5.657,0.315
			c3.447,0.192,5.083,2.072,4.908,5.186C99.771,8.961,98.932,10.32,97.555,11.054z M93.062,5.271l-0.169,3.043l1.378,0.076
			c0.904,0.052,1.46-0.538,1.512-1.465c0.051-0.903-0.463-1.528-1.343-1.578L93.062,5.271z"
        />
        <path
          d="M103.451,19.441l3.92-16l7.583,1.857l-0.884,3.606l-3.723-0.912l-0.605,2.475l3.606,0.884l-0.872,3.561l-3.608-0.884
			l-0.674,2.752l3.792,0.929l-0.883,3.607L103.451,19.441z"
        />
      </g>
      <polygon
        points="97.387,56.049 91.059,56.049 77.181,64.314 82.471,69.201 82.471,114.719 76.238,122.956 103.695,122.956
		97.387,114.719 	"
      />
    </g>
  </svg>
  <!-- Rating 2 -->
  <svg
    v-else-if="rating === 2"
    :class="props.class ?? ''"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 180 180"
    style="enable-background: new 0 0 180 180"
    xml:space="preserve"
    stroke="currentColor"
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="M63.287,122.957l31.102-38.302c2.4-2.88,3.552-5.663,3.552-8.255c0-4.512-3.359-7.968-7.679-7.968
			c-4.608,0-8.352,4.416-8.352,9.888H67.126c0-6.432,2.208-11.999,6.72-16.511s9.983-6.815,16.415-6.815
			c6.239,0,11.519,2.111,15.839,6.335c4.415,4.128,6.623,9.216,6.623,15.071c0,5.279-1.728,10.079-5.184,14.207l-15.646,18.91h22.75
			v13.439H63.287z"
        />
      </g>
      <g>
        <path
          d="M166.446,68.854l-6.03,5.866l-1.292-4.062l5.462-5.136l-0.324-1.021l-6.445,2.049l-1.198-3.767l15.703-4.994l1.717,5.4
			c1.046,3.29-0.128,5.487-3.101,6.433C169.192,70.18,167.622,69.88,166.446,68.854z M170.253,62.597l-2.905,0.924l0.419,1.315
			c0.274,0.862,1.023,1.174,1.908,0.893c0.862-0.274,1.264-0.977,0.996-1.816L170.253,62.597z"
        />
        <path
          d="M160.706,77.347l16.344-2.023l0.958,7.746l-3.685,0.456l-0.471-3.803l-2.526,0.314l0.456,3.685l-3.638,0.449l-0.456-3.685
			l-2.811,0.349l0.479,3.873l-3.685,0.456L160.706,77.347z"
        />
        <path
          d="M161.074,99.792l0.417-3.739l11.934-2.596l-11.5-1.285l0.408-3.643l16.373,1.828l-0.66,5.916l-11.503,2.38l10.694,4.86
			l-0.648,5.797l-16.374-1.829l0.426-3.809l11.5,1.285L161.074,99.792z"
        />
        <path
          d="M159.205,120.694l1.95-4.811l-3.131-2.733l1.468-3.618l13.104,11.529l-1.574,3.884l-17.56-0.542l1.584-3.904
			L159.205,120.694z M163.815,118.195l-1.091,2.692l4.406,0.219L163.815,118.195z"
        />
        <path
          d="M153.155,137.988l-8.321-1.239l2.447-3.49l7.396,1.23l0.616-0.877l-5.538-3.883l2.27-3.236l13.493,9.461l-3.254,4.641
			c-1.982,2.826-4.443,3.225-6.998,1.433C153.765,140.975,153.052,139.544,153.155,137.988z M160.439,137.221l-2.495-1.749
			l-0.794,1.13c-0.519,0.741-0.313,1.525,0.447,2.059c0.742,0.52,1.542,0.412,2.049-0.309L160.439,137.221z"
        />
        <path
          d="M149.045,155.937c-4.009,3.589-8.972,3.079-12.18-0.504c-1.065-1.188-1.762-2.609-2.051-4.075l-1.825,0.068l-0.129-3.785
			l2.027-0.057c0.408-1.579,1.282-3.064,2.666-4.304c4.008-3.591,8.919-3.032,12.143,0.568
			C152.888,147.415,152.823,152.553,149.045,155.937z M140,145.978c-0.515,0.46-0.896,0.962-1.161,1.487l1.469-0.037l0.13,3.75
			l-1.536,0.034c0.259,0.503,0.568,0.992,0.982,1.454c1.842,2.058,4.531,2.525,6.677,0.604c2.182-1.953,1.941-4.613,0.099-6.671
			C144.818,144.542,142.077,144.121,140,145.978z"
        />
        <path
          d="M131.829,156.552l4.529,8.812l-3.537,1.818l-4.529-8.812c-0.85-1.652-2.221-2.393-3.958-1.501
			c-1.673,0.86-1.879,2.439-1.03,4.091l4.519,8.79l-3.537,1.818l-4.518-8.789c-1.818-3.537-1.125-7.188,2.9-9.256
			C126.797,151.4,129.945,152.889,131.829,156.552z"
        />
        <path
          d="M106.017,163.98l5.035-1.255l0.368-4.139l3.789-0.943l-1.61,17.378l-4.065,1.013l-9.894-14.515l4.088-1.018
			L106.017,163.98z M110.749,166.236l-2.819,0.701l2.416,3.692L110.749,166.236z"
        />
        <path
          d="M91.032,161.716l6.019-0.149l0.41,16.463l-5.639,0.141c-3.378,0.084-5.087-1.611-5.157-4.395
			c-0.034-1.403,0.599-2.729,1.585-3.3c-1.375-0.681-2.045-1.807-2.094-3.734C86.081,163.697,88.009,161.792,91.032,161.716z
			 M93.292,168.374l-0.082-3.26l-1.475,0.037c-0.928,0.023-1.505,0.705-1.481,1.68c0.025,1.022,0.634,1.603,1.562,1.579
			L93.292,168.374z M93.451,174.725l-0.075-2.997l-1.333,0.033c-0.832,0.021-1.367,0.558-1.342,1.533
			c0.024,0.976,0.561,1.486,1.417,1.464L93.451,174.725z"
        />
        <path
          d="M82.553,161.425l-2.517,16.284l-3.93-0.607l1.949-12.613l-3.718-0.574l0.567-3.671L82.553,161.425z"
        />
        <path
          d="M71.342,159.371l-5.107,15.664l-7.425-2.421l1.151-3.531l3.646,1.188l0.789-2.422l-3.531-1.151L62,163.211l3.532,1.151
			l0.878-2.694l-3.712-1.21l1.152-3.53L71.342,159.371z"
        />
      </g>
      <g>
        <path
          d="M13.563,111.178l6.025-5.867l1.293,4.059l-5.458,5.138l0.326,1.021l6.44-2.054l1.2,3.766l-15.694,5.002l-1.721-5.397
			c-1.048-3.288,0.125-5.487,3.096-6.435C10.818,109.855,12.388,110.155,13.563,111.178z M9.762,117.437l2.903-0.925l-0.42-1.316
			c-0.275-0.861-1.023-1.172-1.908-0.891c-0.861,0.275-1.262,0.977-0.994,1.816L9.762,117.437z"
        />
        <path
          d="M19.295,102.653l-16.344,2.023l-0.958-7.747l3.685-0.456l0.471,3.803l2.526-0.313l-0.456-3.685l3.637-0.45l0.456,3.685
			l2.811-0.348l-0.48-3.874l3.686-0.455L19.295,102.653z"
        />
        <path
          d="M18.926,80.208l-0.417,3.739L6.576,86.543l11.5,1.285l-0.407,3.644L1.294,89.643l0.661-5.916l11.502-2.38L2.764,76.486
			l0.648-5.796l16.374,1.829l-0.425,3.81l-11.5-1.285L18.926,80.208z"
        />
        <path
          d="M20.795,59.306l-1.95,4.811l3.131,2.733l-1.467,3.619L7.404,58.939l1.575-3.883l17.559,0.541l-1.583,3.905L20.795,59.306z
			 M16.185,61.805l1.091-2.692l-4.406-0.219L16.185,61.805z"
        />
        <path
          d="M26.846,42.012l8.321,1.239l-2.447,3.49l-7.396-1.231l-0.615,0.877l5.537,3.883l-2.27,3.236l-13.491-9.459l3.253-4.641
			c1.983-2.826,4.442-3.226,6.997-1.434C26.235,39.025,26.949,40.456,26.846,42.012z M19.561,42.779l2.496,1.75l0.792-1.131
			c0.519-0.741,0.313-1.525-0.447-2.059c-0.74-0.519-1.542-0.412-2.048,0.309L19.561,42.779z"
        />
        <path
          d="M30.956,24.064c4.008-3.59,8.971-3.08,12.18,0.504c1.064,1.188,1.762,2.609,2.051,4.074l1.825-0.067l0.128,3.784
			l-2.026,0.058c-0.409,1.578-1.282,3.064-2.667,4.303c-4.008,3.59-8.917,3.033-12.143-0.568
			C27.112,32.585,27.177,27.447,30.956,24.064z M40,34.022c0.515-0.46,0.896-0.963,1.161-1.487l-1.469,0.037l-0.13-3.751
			l1.537-0.032c-0.26-0.504-0.569-0.993-0.983-1.455c-1.842-2.058-4.531-2.525-6.677-0.604c-2.182,1.953-1.941,4.614-0.099,6.671
			C35.182,35.458,37.924,35.88,40,34.022z"
        />
        <path
          d="M48.172,23.448l-4.529-8.812l3.537-1.818l4.529,8.812c0.85,1.652,2.222,2.394,3.958,1.501
			c1.673-0.86,1.879-2.439,1.03-4.091l-4.519-8.79l3.538-1.817l4.518,8.789c1.818,3.537,1.124,7.188-2.9,9.256
			C53.203,28.6,50.055,27.111,48.172,23.448z"
        />
        <path
          d="M73.983,16.02l-5.035,1.255l-0.368,4.139l-3.789,0.943l1.61-17.378l4.066-1.012l9.894,14.515l-4.089,1.017L73.983,16.02z
			 M69.251,13.764l2.818-0.701l-2.416-3.692L69.251,13.764z"
        />
        <path
          d="M88.969,18.284l-6.018,0.15L82.54,1.97l5.639-0.141c3.378-0.084,5.086,1.611,5.156,4.395
			c0.035,1.404-0.599,2.729-1.584,3.301c1.373,0.68,2.044,1.806,2.093,3.733C93.919,16.303,91.991,18.208,88.969,18.284z
			 M86.55,5.275l0.075,2.997l1.332-0.033c0.833-0.021,1.367-0.558,1.342-1.533s-0.561-1.486-1.417-1.465L86.55,5.275z
			 M86.708,11.626l0.082,3.26l1.476-0.036c0.928-0.023,1.505-0.705,1.481-1.681c-0.026-1.022-0.635-1.603-1.563-1.579L86.708,11.626
			z"
        />
        <path
          d="M97.447,18.575l2.517-16.284l3.93,0.607l-1.949,12.613l3.718,0.574l-0.567,3.671L97.447,18.575z"
        />
        <path
          d="M108.659,20.63l5.108-15.664l7.425,2.421l-1.151,3.531l-3.644-1.188l-0.791,2.421l3.531,1.151l-1.136,3.486l-3.532-1.151
			l-0.878,2.694l3.712,1.21l-1.151,3.53L108.659,20.63z"
        />
      </g>
    </g>
  </svg>
  <!-- Rating 3 -->
  <svg
    v-else-if="rating === 3"
    :class="props.class ?? ''"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 180 180"
    stroke="currentColor"
    fill="currentColor"
    style="enable-background: new 0 0 180 180"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M75.805,103.469c2.592,4.704,6.911,7.008,12.959,7.008c6.815,0,11.808-4.031,11.808-10.079
			c0-6.144-4.896-9.983-13.344-9.983c-4.991,0-9.023,0.384-11.999,1.248L91.74,69.487H70.238V56.049h45.5l-17.854,23.71h0.097
			c4.896,0.48,9.022,2.592,12.383,6.527c3.359,3.937,4.991,8.447,4.991,13.728c0,6.815-2.399,12.575-7.199,17.087
			s-11.135,6.815-19.103,6.815c-11.136,0-20.351-5.184-24.862-13.247L75.805,103.469z"
        />
      </g>
      <g>
        <path
          d="M173.968,99.548l-12.711-1.134l0.353-3.96l12.712,1.134l0.245-2.751l3.7,0.329l-0.842,9.439l-3.699-0.33L173.968,99.548z"
        />
        <path
          d="M165.287,114.97l-8.269,1.534l1.179-4.095l7.391-1.242l0.296-1.029l-6.496-1.869l1.092-3.797l15.83,4.556l-1.567,5.443
			c-0.955,3.316-3.149,4.493-6.146,3.631C166.834,117.594,165.696,116.475,165.287,114.97z M171.923,111.876l-2.928-0.843
			l-0.381,1.327c-0.25,0.869,0.2,1.544,1.092,1.8c0.869,0.25,1.592-0.111,1.835-0.958L171.923,111.876z"
        />
        <path
          d="M155.801,118.836l14.708,7.406l-3.511,6.972l-3.315-1.669l1.723-3.422l-2.274-1.146l-1.669,3.316l-3.273-1.648
			l1.669-3.316l-2.529-1.273l-1.755,3.486l-3.315-1.67L155.801,118.836z M172.005,131.792l-3.576,0.117l1.617-3.209l3.64-0.245
			L172.005,131.792z"
        />
        <path
          d="M155.634,139.605l-1.895,1.163c-1.995,1.176-3.895,1.32-5.85-0.159c-2.657-2.01-2.791-5.066-0.795-7.704
			c2.844-3.758,5.808-2.59,7.64-1.024l-2.27,2.998c-0.807-0.67-1.866-0.964-2.656,0.08c-0.546,0.72-0.55,1.554,0.209,2.128
			c0.74,0.561,1.503,0.302,2.472-0.308c0.768-0.464,1.517-0.941,2.423-1.391c1.688-0.812,3.511-0.536,5.105,0.67
			c2.296,1.737,2.626,4.614,0.688,7.176c-2.34,3.095-4.954,2.878-7.187,0.979l2.212-2.923c0.801,0.637,1.687,0.649,2.246-0.092
			c0.547-0.72,0.383-1.411-0.168-1.828C157.259,138.955,156.651,138.971,155.634,139.605z"
        />
        <path
          d="M132.05,148.119l4.835-3.589l9.818,13.223l-4.529,3.362c-2.714,2.015-5.086,1.614-6.747-0.622
			c-0.837-1.128-1.083-2.575-0.607-3.611c-1.514,0.236-2.711-0.299-3.86-1.846C129.146,152.59,129.625,149.921,132.05,148.119z
			 M137.734,152.259l-1.944-2.618l-1.185,0.88c-0.745,0.554-0.826,1.443-0.244,2.227c0.61,0.821,1.442,0.944,2.188,0.392
			L137.734,152.259z M141.522,157.362l-1.788-2.408l-1.071,0.794c-0.669,0.497-0.796,1.243-0.213,2.028
			c0.581,0.782,1.314,0.891,2.001,0.379L141.522,157.362z"
        />
        <path
          d="M124.665,171.452c-4.953,2.1-9.482,0.013-11.359-4.414c-1.876-4.427-0.285-9.211,4.579-11.274
			c4.953-2.1,9.417,0.017,11.303,4.465C131.055,164.632,129.332,169.472,124.665,171.452z M119.355,159.172
			c-2.673,1.134-3.283,3.72-2.234,6.195c1.05,2.477,3.42,3.799,6.028,2.692c2.673-1.134,3.262-3.709,2.212-6.185
			C124.302,159.376,121.876,158.105,119.355,159.172z"
        />
        <path
          d="M104.766,170.178l-1.377-9.638l3.794-0.542l2.33,16.307l-4.053,0.579l-7.534-8.975l1.406,9.85l-3.794,0.542l-2.33-16.308
			l3.889-0.555L104.766,170.178z"
        />
      </g>
      <g>
        <path
          d="M6.032,80.45l12.712,1.134l-0.354,3.96L5.679,84.411l-0.246,2.751l-3.699-0.33l0.841-9.439l3.7,0.33L6.032,80.45z"
        />
        <path
          d="M14.714,65.029l8.269-1.533l-1.178,4.094l-7.392,1.241l-0.296,1.03l6.496,1.869l-1.093,3.796L3.691,70.972l1.566-5.445
			c0.955-3.315,3.151-4.492,6.147-3.63C13.167,62.405,14.305,63.524,14.714,65.029z M8.078,68.123l2.927,0.842l0.383-1.325
			c0.25-0.869-0.2-1.544-1.092-1.801c-0.869-0.25-1.593,0.11-1.836,0.957L8.078,68.123z"
        />
        <path
          d="M7.995,48.206l3.576-0.117l-1.617,3.209l-3.64,0.245L7.995,48.206z M24.199,61.162L9.49,53.755l3.511-6.972l3.315,1.669
			l-1.723,3.422l2.274,1.146l1.669-3.315l3.273,1.648l-1.669,3.315l2.529,1.273l1.755-3.485l3.315,1.669L24.199,61.162z"
        />
        <path
          d="M24.366,40.393l1.895-1.162c1.995-1.177,3.895-1.321,5.85,0.158c2.657,2.011,2.791,5.066,0.795,7.705
			c-2.844,3.757-5.808,2.589-7.64,1.023l2.27-2.998c0.807,0.67,1.866,0.965,2.656-0.079c0.546-0.721,0.549-1.554-0.21-2.129
			c-0.74-0.561-1.503-0.302-2.472,0.309c-0.768,0.463-1.517,0.941-2.423,1.39c-1.689,0.811-3.511,0.536-5.105-0.67
			c-2.296-1.737-2.626-4.613-0.688-7.176c2.34-3.094,4.953-2.877,7.186-0.979l-2.211,2.923c-0.802-0.636-1.687-0.649-2.246,0.092
			c-0.546,0.721-0.382,1.412,0.168,1.828C22.74,41.044,23.349,41.027,24.366,40.393z"
        />
        <path
          d="M47.949,31.879l-4.835,3.59l-9.818-13.224l4.529-3.362c2.714-2.015,5.086-1.613,6.747,0.622
			c0.837,1.128,1.083,2.576,0.607,3.612c1.514-0.236,2.711,0.298,3.86,1.845C50.854,27.409,50.375,30.077,47.949,31.879z
			 M38.478,22.637l1.788,2.407l1.071-0.794c0.668-0.496,0.796-1.243,0.213-2.027c-0.581-0.782-1.313-0.892-2.001-0.38L38.478,22.637
			z M42.266,27.739l1.943,2.617l1.186-0.879c0.745-0.553,0.826-1.442,0.244-2.226c-0.61-0.822-1.443-0.946-2.188-0.393
			L42.266,27.739z"
        />
        <path
          d="M55.336,8.548c4.952-2.101,9.482-0.014,11.358,4.413c1.876,4.426,0.287,9.212-4.579,11.273
			c-4.953,2.1-9.417-0.015-11.302-4.463C48.946,15.368,50.668,10.527,55.336,8.548z M60.644,20.826
			c2.674-1.133,3.284-3.718,2.234-6.195c-1.049-2.476-3.42-3.798-6.027-2.691c-2.674,1.133-3.262,3.709-2.212,6.186
			C55.698,20.623,58.124,21.894,60.644,20.826z"
        />
        <path
          d="M75.235,9.821l1.377,9.639l-3.794,0.542l-2.33-16.307l4.053-0.579l7.534,8.974l-1.408-9.85l3.795-0.541l2.329,16.306
			l-3.888,0.556L75.235,9.821z"
        />
      </g>
    </g>
  </svg>
  <!-- Rating 4 -->
  <svg
    v-else-if="rating === 4"
    :class="props.class ?? ''"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 180 180"
    stroke="currentColor"
    fill="currentColor"
    style="enable-background: new 0 0 180 180"
    xml:space="preserve"
  >
    <g>
      <polygon
        points="108.897,92.629 103.204,92.629 103.204,80.151 88.804,80.151 88.804,92.629 76.062,92.629 95.164,51.353
		79.517,51.353 54.751,105.781 88.804,105.781 88.804,118.26 103.204,118.26 103.204,105.781 108.897,105.781 	"
      />
      <g>
        <path
          d="M152.959,61.024l-2.09-5.647l15.449-5.717l1.958,5.29c1.173,3.17,0.122,5.336-2.49,6.303
			c-1.317,0.488-2.776,0.317-3.637-0.431c-0.199,1.521-1.048,2.52-2.855,3.188C156.436,65.068,154.009,63.861,152.959,61.024z
			 M158.529,56.73l-3.059,1.133l0.513,1.383c0.322,0.872,1.153,1.198,2.068,0.858c0.96-0.355,1.312-1.119,0.989-1.989L158.529,56.73
			z M164.489,54.524l-2.812,1.041l0.462,1.25c0.29,0.782,0.972,1.113,1.887,0.774c0.916-0.339,1.225-1.011,0.927-1.815
			L164.489,54.524z"
        />
        <path
          d="M173.294,74.647c0.637,5.342-2.63,9.111-7.403,9.679c-4.773,0.57-8.925-2.291-9.551-7.537
			c-0.637-5.341,2.638-9.038,7.436-9.611C168.525,66.612,172.694,69.614,173.294,74.647z M160.024,76.325
			c0.344,2.883,2.657,4.189,5.327,3.872c2.67-0.318,4.601-2.227,4.266-5.038c-0.344-2.884-2.654-4.167-5.324-3.848
			C161.598,71.633,159.701,73.608,160.024,76.325z"
        />
        <path
          d="M166.552,93.436l-9.642-1.349l0.531-3.796l16.312,2.283l-0.567,4.054l-10.712,4.751l9.854,1.379l-0.531,3.796
			l-16.313-2.284l0.545-3.89L166.552,93.436z"
        />
      </g>
      <g>
        <path
          d="M23.538,49.102l-2.986,5.23L6.246,46.163l2.798-4.899c1.676-2.936,4.011-3.521,6.431-2.14
			c1.219,0.696,2.025,1.924,2.006,3.064c1.292-0.825,2.604-0.816,4.278,0.14C24.405,43.84,25.037,46.476,23.538,49.102z
			 M11.149,44.439l2.605,1.488l0.662-1.157c0.413-0.724,0.229-1.46-0.618-1.943c-0.848-0.484-1.562-0.289-1.987,0.456L11.149,44.439
			z M16.669,47.592l2.833,1.618l0.731-1.282c0.46-0.807,0.177-1.653-0.671-2.138c-0.889-0.508-1.701-0.286-2.161,0.521
			L16.669,47.592z"
        />
        <path
          d="M20.919,24.766c3.685-3.918,8.671-3.836,12.173-0.543s3.977,8.312,0.357,12.162c-3.684,3.919-8.622,3.784-12.141,0.475
			C17.824,33.583,17.446,28.459,20.919,24.766z M30.728,33.858c1.99-2.115,1.506-4.727-0.454-6.569
			c-1.959-1.842-4.661-2.095-6.601-0.032c-1.988,2.116-1.488,4.71,0.471,6.552C26.121,35.667,28.854,35.854,30.728,33.858z"
        />
        <path
          d="M39.628,17.884l5.158,8.256l-3.25,2.031L32.808,14.2l3.473-2.168l10.521,5.158l-5.272-8.438l3.25-2.03l8.729,13.97
			l-3.331,2.081L39.628,17.884z"
        />
      </g>
      <g>
        <path
          d="M73.878,156.083l5.953,0.912l-2.495,16.283l-5.576-0.855c-3.342-0.512-4.726-2.481-4.304-5.234
			c0.213-1.389,1.07-2.582,2.141-2.972c-1.232-0.911-1.694-2.138-1.402-4.044C68.656,157.161,70.89,155.624,73.878,156.083z
			 M73.97,169.318l0.453-2.965l-1.317-0.202c-0.823-0.126-1.444,0.309-1.592,1.273c-0.148,0.965,0.29,1.562,1.138,1.691
			L73.97,169.318z M74.932,163.036l0.494-3.225l-1.458-0.223c-0.919-0.141-1.608,0.428-1.756,1.393
			c-0.155,1.012,0.344,1.689,1.261,1.83L74.932,163.036z"
        />
        <path
          d="M52.101,167.255c-4.98-2.035-6.691-6.719-4.873-11.169c1.817-4.45,6.333-6.693,11.225-4.695
			c4.979,2.034,6.624,6.691,4.798,11.164C61.441,166.983,56.793,169.172,52.101,167.255z M57.072,154.837
			c-2.689-1.099-4.953,0.291-5.971,2.779c-1.017,2.49-0.285,5.104,2.336,6.174c2.688,1.099,4.931-0.299,5.948-2.789
			C60.412,158.489,59.605,155.872,57.072,154.837z"
        />
        <path
          d="M38.943,152.245l5.858-7.777l3.061,2.307l-9.911,13.156l-3.27-2.463l1.042-11.672l-5.986,7.948l-3.062-2.306l9.912-13.157
			l3.137,2.363L38.943,152.245z"
        />
      </g>
    </g>
  </svg>
  <!-- Rating 5 -->
  <svg
    v-else-if="rating === 5"
    :class="props.class ?? ''"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 180 180"
    stroke="currentColor"
    fill="currentColor"
    style="enable-background: new 0 0 180 180"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M76.099,101.868c2.016,4.319,6.623,7.199,11.999,7.199c5.855,0,10.751-4.319,10.751-10.079
			c0-6.239-5.376-10.079-13.055-10.079c-6.528,0-12.096,1.056-16.703,3.071l6.239-37.341h33.693v13.438h-22.75l-1.439,8.832
			c1.344-0.48,3.071-0.672,5.087-0.672c6.528,0,12.096,2.207,16.703,6.719c4.704,4.416,7.008,9.792,7.008,16.031
			c0,6.432-2.496,11.999-7.487,16.606c-4.992,4.608-11.04,6.912-18.047,6.912c-10.464,0-19.583-5.376-23.902-13.535L76.099,101.868z
			"
        />
      </g>
      <g>
        <path
          d="M141.885,40.129l-2.609-2.71l5.628-10.842l-8.337,8.027l-2.544-2.643l11.872-11.429l4.129,4.289l-5.519,10.371
			l10.573-5.121l4.046,4.202l-11.871,11.43l-2.659-2.761l8.337-8.028L141.885,40.129z"
        />
        <path
          d="M167.557,47.92c2.46,4.787,0.712,9.46-3.565,11.659c-4.278,2.197-9.169,0.963-11.586-3.739
			c-2.459-4.785-0.679-9.395,3.62-11.604C160.283,42.048,165.239,43.41,167.557,47.92z M155.699,54.121
			c1.328,2.584,3.952,3.002,6.345,1.773c2.394-1.231,3.538-3.692,2.242-6.213c-1.327-2.583-3.94-2.98-6.333-1.75
			C155.538,49.172,154.448,51.687,155.699,54.121z"
        />
        <path
          d="M172.516,59.707l1.124,4.259l-3.767,3.359l4.94,1.084l1.069,4.051l-9.226-2.39l-7.896,2.086l-1.01-3.822l7.827-2.066
			L172.516,59.707z"
        />
        <path
          d="M160.163,79.414l16.431-1.232l0.585,7.788l-3.704,0.278l-0.287-3.823l-2.54,0.191l0.277,3.703l-3.656,0.273l-0.278-3.703
			l-2.826,0.212l0.293,3.895l-3.704,0.277L160.163,79.414z"
        />
        <path
          d="M170.113,95.928l-9.654-1.271l0.501-3.801l16.335,2.152l-0.535,4.062l-10.676,4.837l9.866,1.301l-0.5,3.8l-16.335-2.153
			l0.513-3.895L170.113,95.928z"
        />
      </g>
      <g>
        <path
          d="M22.041,62.647l-1.268,3.543L8.559,65.967l10.897,3.901l-1.237,3.452L2.706,67.766l2.006-5.605l11.743,0.335L7.167,55.3
			l1.968-5.493l15.514,5.555l-1.292,3.61L12.46,55.069L22.041,62.647z"
        />
        <path
          d="M17.611,36.187c3.193-4.33,8.157-4.841,12.027-1.986c3.871,2.855,4.939,7.785,1.801,12.039
			c-3.194,4.331-8.114,4.784-12.005,1.915C15.583,45.312,14.6,40.27,17.611,36.187z M28.437,44.054
			c1.724-2.337,0.932-4.875-1.233-6.472c-2.166-1.598-4.88-1.528-6.562,0.752c-1.725,2.338-0.918,4.855,1.248,6.453
			C24.073,46.398,26.811,46.258,28.437,44.054z"
        />
        <path
          d="M25.965,26.506l3.315-2.899l4.683,1.882l-1.224-4.907l3.154-2.76l1.959,9.328l5.377,6.146l-2.975,2.604l-5.331-6.095
			L25.965,26.506z"
        />
        <path
          d="M49.108,28.812l-8.407-14.171l6.717-3.984l1.895,3.193l-3.297,1.957l1.3,2.19l3.195-1.895l1.87,3.153l-3.193,1.896
			l1.445,2.436l3.358-1.991l1.896,3.194L49.108,28.812z"
        />
        <path
          d="M59.479,12.559l3.151,9.216l-3.628,1.239l-5.33-15.59l3.874-1.325l9.079,7.415l-3.221-9.418l3.627-1.24l5.331,15.591
			l-3.718,1.271L59.479,12.559z"
        />
      </g>
      <g>
        <path
          d="M90.734,160.282l3.757-0.196l4.506,11.353l-0.605-11.558l3.662-0.191l0.863,16.457l-5.946,0.311l-4.223-10.962
			l-3.055,11.344l-5.826,0.306l-0.862-16.457l3.828-0.201l0.606,11.559L90.734,160.282z"
        />
        <path
          d="M68.032,174.58c-5.228-1.276-7.614-5.658-6.475-10.33c1.141-4.673,5.277-7.561,10.413-6.308
			c5.227,1.276,7.545,5.641,6.399,10.336C77.235,172.928,72.959,175.781,68.032,174.58z M71.112,161.557
			c-2.822-0.688-4.856,1.021-5.494,3.635c-0.638,2.614,0.474,5.091,3.226,5.763c2.822,0.688,4.834-1.026,5.472-3.64
			C74.958,164.677,73.772,162.206,71.112,161.557z"
        />
        <path
          d="M55.877,170.607l-3.954-1.939l-0.072-5.047l-4.055,3.023l-3.762-1.845l7.849-5.406l3.595-7.333l3.55,1.74l-3.564,7.268
			L55.877,170.607z"
        />
        <path
          d="M49.076,148.366l-9.828,13.224l-6.27-4.658l2.217-2.981l3.076,2.287l1.521-2.045l-2.98-2.216l2.187-2.943l2.981,2.217
			l1.69-2.274l-3.135-2.329l2.216-2.982L49.076,148.366z"
        />
        <path
          d="M30.08,145.07l7.284-6.462l2.544,2.866L27.583,152.41l-2.717-3.064l3.336-11.234l-7.445,6.604l-2.543-2.867l12.324-10.935
			l2.607,2.939L30.08,145.07z"
        />
      </g>
    </g>
  </svg>
  <!-- Rating 6 -->
  <svg
    v-else-if="rating === 6"
    :class="props.class ?? ''"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 180 180"
    stroke="currentColor"
    fill="currentColor"
    style="enable-background: new 0 0 180 180"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M65.428,90.797c1.056-2.784,2.112-4.991,3.168-6.815s2.88-4.704,5.376-8.447l13.343-20.351h16.223L89.714,75.726
			c0.48-0.191,1.344-0.288,2.592-0.288c5.28,0,10.271,2.112,14.976,6.432c4.799,4.224,7.199,9.695,7.199,16.511
			c0,6.72-2.496,12.479-7.487,17.184c-4.896,4.703-10.943,7.007-17.951,7.007c-6.911,0-12.863-2.304-17.759-7.007
			c-4.896-4.704-7.392-10.368-7.392-16.991C63.892,96.076,64.372,93.484,65.428,90.797z M89.042,109.132
			c2.976,0,5.472-1.057,7.487-3.072c2.112-2.111,3.168-4.607,3.168-7.487c0-2.784-1.056-5.279-3.168-7.296
			c-2.111-2.111-4.607-3.167-7.487-3.167c-5.855,0-10.463,4.703-10.463,10.463C78.579,104.428,83.282,109.132,89.042,109.132z"
        />
      </g>
      <g>
        <path
          d="M152.542,43.079l-1.034-1.396l-5.281,3.907l-2.351-3.176l13.243-9.8l3.369,4.556c2.054,2.774,1.652,5.233-0.854,7.088
			C157.183,46.07,154.425,45.625,152.542,43.079z M156.713,37.83l-2.449,1.813l0.82,1.109c0.539,0.728,1.348,0.78,2.094,0.228
			c0.728-0.537,0.88-1.332,0.356-2.039L156.713,37.83z"
        />
        <path
          d="M158.672,59.064l-2.208-4.697l-4.133,0.443l-1.66-3.534l17.36-1.794l1.783,3.79l-12.317,12.527l-1.793-3.814
			L158.672,59.064z M159.967,53.983l1.235,2.629l3.153-3.087L159.967,53.983z"
        />
        <path
          d="M164.339,66.117l9.687-2.052l0.823,3.89l-9.687,2.052c-1.816,0.385-2.892,1.513-2.487,3.422
			c0.39,1.84,1.857,2.452,3.674,2.067l9.664-2.045l0.823,3.889l-9.663,2.046c-3.889,0.823-7.227-0.805-8.164-5.229
			C158.047,69.615,160.31,66.97,164.339,66.117z"
        />
        <path
          d="M177.567,83.549l-0.13,4.449l-11.557,3.522l11.333,4.188l-0.123,4.235l-16.29-6.546l0.125-4.307L177.567,83.549z"
        />
        <path
          d="M165.019,112.294l-8.233,1.719l1.086-4.12l7.364-1.408l0.273-1.036l-6.539-1.724l1.007-3.821l15.933,4.2l-1.444,5.479
			c-0.88,3.339-3.05,4.564-6.065,3.77C166.626,114.885,165.462,113.79,165.019,112.294z M171.586,109.052l-2.946-0.777l-0.353,1.336
			c-0.23,0.875,0.234,1.538,1.133,1.775c0.875,0.229,1.59-0.147,1.814-0.999L171.586,109.052z"
        />
        <path
          d="M155.607,116.386l14.872,7.081l-3.356,7.049l-3.354-1.596l1.648-3.461l-2.3-1.094l-1.597,3.352l-3.31-1.575l1.596-3.353
			l-2.557-1.218l-1.678,3.524l-3.353-1.597L155.607,116.386z"
        />
      </g>
      <g>
        <path
          d="M13.418,69.514l-0.421,1.687l6.376,1.587l-0.955,3.834L2.433,72.642l1.369-5.498c0.834-3.348,2.985-4.604,6.013-3.851
			C12.772,64.03,14.182,66.442,13.418,69.514z M6.714,69.636l2.957,0.736l0.333-1.341c0.219-0.877-0.255-1.534-1.155-1.759
			c-0.879-0.219-1.588,0.17-1.801,1.023L6.714,69.636z"
        />
        <path
          d="M21.847,54.611l-2.219,4.693l2.973,2.906l-1.669,3.53L8.499,53.491l1.791-3.789l17.501,1.53l-1.801,3.811L21.847,54.611z
			 M17.104,56.846l1.242-2.625l-4.389-0.469L17.104,56.846z"
        />
        <path
          d="M23.682,45.754l-7.747-6.167l2.477-3.109l7.747,6.167c1.452,1.157,3.006,1.268,4.221-0.26
			c1.172-1.471,0.711-2.994-0.742-4.15l-7.728-6.151l2.475-3.111l7.729,6.153c3.11,2.476,3.978,6.087,1.16,9.625
			C30.383,48.381,26.904,48.32,23.682,45.754z"
        />
        <path
          d="M28.71,24.457l3.516-2.732l10.072,6.674l-3.983-11.407l3.347-2.601l5.315,16.731l-3.401,2.646L28.71,24.457z"
        />
        <path
          d="M58.9,15.837l6.561,5.26l-3.87,1.782l-5.766-4.787l-0.974,0.446l2.826,6.141l-3.59,1.652l-6.884-14.964L52.35,9
			c3.134-1.442,5.459-0.547,6.763,2.286C59.878,12.951,59.773,14.544,58.9,15.837z M52.225,12.831l1.273,2.768l1.254-0.576
			c0.822-0.379,1.039-1.16,0.651-2.003c-0.379-0.822-1.124-1.134-1.924-0.766L52.225,12.831z"
        />
        <path
          d="M68.015,20.498L64.013,4.519l7.573-1.897l0.903,3.603l-3.718,0.931l0.618,2.472l3.603-0.903l0.891,3.557l-3.602,0.902
			l0.688,2.748l3.787-0.949l0.901,3.603L68.015,20.498z"
        />
      </g>
      <g>
        <path
          d="M101.871,166.412l1.712-0.297l-1.121-6.475l3.894-0.674l2.812,16.232l-5.583,0.967c-3.401,0.589-5.419-0.874-5.95-3.947
			C97.113,169.216,98.75,166.951,101.871,166.412z M104.688,172.495l-0.521-3.003l-1.361,0.236
			c-0.891,0.154-1.302,0.854-1.143,1.768c0.154,0.893,0.795,1.385,1.663,1.234L104.688,172.495z"
        />
        <path
          d="M84.833,164.712l5.188,0.137l1.46-3.893l3.903,0.102l-6.19,16.32l-4.188-0.109l-5.663-16.629l4.213,0.109L84.833,164.712z
			 M88.792,168.151l-2.902-0.075l1.343,4.202L88.792,168.151z"
        />
        <path
          d="M75.988,166.61l-2.516,9.577l-3.846-1.01l2.516-9.578c0.473-1.795-0.053-3.262-1.94-3.758
			c-1.819-0.479-3.026,0.559-3.498,2.354l-2.509,9.555l-3.846-1.01l2.51-9.554c1.01-3.845,3.963-6.097,8.338-4.947
			C75.687,159.417,77.034,162.626,75.988,166.61z"
        />
        <path
          d="M54.471,170.604l-3.918-2.112l2.041-11.91l-8.829,8.248l-3.729-2.012l13.163-11.617l3.791,2.046L54.471,170.604z"
        />
        <path
          d="M34.419,146.488l2.159-8.13l3.194,2.82l-2.049,7.212l0.805,0.71l4.476-5.069l2.962,2.616l-10.905,12.351l-4.248-3.75
			c-2.589-2.285-2.709-4.774-0.645-7.112C31.382,146.76,32.883,146.212,34.419,146.488z M34.367,153.812l2.018-2.285l-1.035-0.914
			c-0.678-0.599-1.48-0.48-2.096,0.215c-0.598,0.679-0.582,1.487,0.078,2.07L34.367,153.812z"
        />
        <path
          d="M34.988,136.24l-13.004,10.11l-4.791-6.163l2.932-2.279l2.352,3.025l2.011-1.563l-2.279-2.932l2.894-2.25l2.279,2.931
			l2.237-1.737l-2.396-3.082l2.932-2.279L34.988,136.24z"
        />
      </g>
    </g>
  </svg>
  <!-- Rating 7 -->
  <svg
    v-else-if="rating === 7"
    :class="props.class ?? ''"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 180 180"
    stroke="currentColor"
    fill="currentColor"
    style="enable-background: new 0 0 180 180"
    xml:space="preserve"
  >
    <g>
      <g>
        <path d="M71.244,122.597l24.958-53.469H66.349V55.69h50.973l-30.814,66.907H71.244z" />
      </g>
      <g>
        <path
          d="M145.168,43.64l-2.482-2.825l6.115-10.568l-8.691,7.635l-2.419-2.753l12.376-10.87l3.927,4.471l-5.984,10.103
			l10.791-4.632l3.849,4.382l-12.375,10.871l-2.529-2.879l8.691-7.636L145.168,43.64z"
        />
        <path d="M153.762,56.553l-1.926-3.478l14.414-7.981l1.926,3.479L153.762,56.553z" />
        <path
          d="M166.278,60.668l-9.235,3.075l-1.211-3.635l15.626-5.204l1.293,3.885l-7.483,9.013l9.438-3.142l1.211,3.635l-15.626,5.202
			l-1.241-3.725L166.278,60.668z"
        />
        <path
          d="M165.646,85.967l-0.419-5.174l-4.025-1.035l-0.315-3.893l16.888,4.413l0.338,4.178l-15.931,7.405l-0.34-4.201
			L165.646,85.967z M168.641,81.663l0.234,2.896l4.036-1.784L168.641,81.663z"
        />
        <path
          d="M160.966,100.377l0.84-5.962l16.308,2.296l-0.786,5.585c-0.472,3.346-2.424,4.754-5.182,4.365
			c-1.39-0.195-2.594-1.038-2.996-2.104c-0.896,1.243-2.117,1.721-4.025,1.452C162.107,105.585,160.545,103.369,160.966,100.377z
			 M167.903,99.239l-3.229-0.454l-0.205,1.46c-0.13,0.92,0.447,1.602,1.413,1.738c1.014,0.143,1.686-0.364,1.815-1.283
			L167.903,99.239z M174.195,100.125l-2.969-0.418l-0.186,1.32c-0.116,0.824,0.325,1.439,1.292,1.575
			c0.966,0.137,1.558-0.31,1.677-1.157L174.195,100.125z"
        />
        <path
          d="M159.282,108.673l15.637,5.19l-1.253,3.774l-12.111-4.022l-1.186,3.57l-3.525-1.17L159.282,108.673z"
        />
        <path
          d="M155.398,119.361l14.598,7.63l-3.615,6.92l-3.291-1.721l1.775-3.396l-2.258-1.179l-1.721,3.291l-3.248-1.699l1.72-3.29
			l-2.511-1.312l-1.808,3.46l-3.291-1.72L155.398,119.361z"
        />
      </g>
      <g>
        <path
          d="M20.369,72.75l-0.789,3.678l-12.13,1.39l11.311,2.428l-0.77,3.584L1.887,80.371l1.249-5.817l11.679-1.218l-10.15-5.904
			l1.225-5.701l16.104,3.456l-0.804,3.747L9.878,66.507L20.369,72.75z"
        />
        <path d="M25.616,58.151l-1.646,3.62l-15-6.816l1.646-3.62L25.616,58.151z" />
        <path
          d="M21.459,45.65l7.975,5.582l-2.196,3.14l-13.492-9.445l2.347-3.354l11.699,0.637l-8.15-5.705l2.197-3.139l13.492,9.445
			l-2.252,3.217L21.459,45.65z"
        />
        <path
          d="M42.148,31.078l-3.903,3.421l1.567,3.849l-2.938,2.572L30.354,24.73l3.152-2.762l15.44,8.378l-3.17,2.777L42.148,31.078z
			 M36.908,31.238l2.185-1.915l-3.837-2.177L36.908,31.238z"
        />
        <path
          d="M56.511,26.249l-5.287,2.879l-7.878-14.462l4.954-2.698c2.968-1.616,5.261-0.888,6.593,1.558
			c0.672,1.232,0.713,2.701,0.096,3.66c1.533-0.021,2.645,0.674,3.566,2.367C60.013,22.228,59.165,24.802,56.511,26.249z
			 M48.423,15.776l1.434,2.633l1.171-0.638c0.731-0.397,0.961-1.119,0.494-1.977s-1.176-1.066-1.929-0.655L48.423,15.776z
			 M51.462,21.356l1.559,2.863l1.296-0.706c0.815-0.444,1.019-1.313,0.553-2.17c-0.489-0.899-1.297-1.137-2.111-0.693L51.462,21.356
			z"
        />
        <path
          d="M64.172,22.652l-5.15-15.648l3.775-1.244l3.991,12.122l3.572-1.176l1.162,3.527L64.172,22.652z"
        />
        <path
          d="M75.072,19.4L72.497,3.132l7.71-1.222l0.582,3.668l-3.785,0.6l0.398,2.516l3.667-0.581l0.573,3.62l-3.668,0.582
			l0.443,2.797l3.856-0.61l0.58,3.668L75.072,19.4z"
        />
      </g>
      <g>
        <path
          d="M108.929,158.869l3.603-1.082l7.066,9.958l-3.328-11.08l3.512-1.055l4.737,15.776l-5.7,1.711l-6.698-9.645l-0.276,11.739
			l-5.586,1.678l-4.738-15.776l3.671-1.102l3.327,11.079L108.929,158.869z"
        />
        <path d="M93.611,161.312l3.965-0.305l1.26,16.428l-3.964,0.305L93.611,161.312z" />
        <path
          d="M84.663,170.982l1.045-9.678l3.81,0.412l-1.77,16.373l-4.068-0.439l-5.084-10.555l-1.068,9.891l-3.811-0.412l1.769-16.373
			l3.905,0.421L84.663,170.982z"
        />
        <path
          d="M61.92,159.882l4.879,1.772l2.617-3.23l3.67,1.333l-11.039,13.521l-3.939-1.43L58,154.279l3.963,1.439L61.92,159.882z
			 M64.586,164.396l-2.729-0.991l-0.057,4.413L64.586,164.396z"
        />
        <path
          d="M50.762,149.633l5.073,3.243l-8.872,13.875l-4.752-3.039c-2.847-1.819-3.314-4.182-1.814-6.527
			c0.757-1.183,2.023-1.927,3.16-1.849c-0.759-1.333-0.685-2.642,0.354-4.266C45.551,148.504,48.215,148.004,50.762,149.633z
			 M45.487,161.769l1.615-2.526l-1.123-0.719c-0.701-0.448-1.444-0.302-1.97,0.52c-0.525,0.822-0.366,1.546,0.355,2.007
			L45.487,161.769z M48.911,156.414l1.757-2.746l-1.244-0.795c-0.782-0.5-1.642-0.259-2.168,0.562
			c-0.551,0.862-0.371,1.685,0.412,2.185L48.911,156.414z"
        />
        <path
          d="M43.919,144.65l-11.225,12.06l-2.911-2.708l8.696-9.342l-2.754-2.562l2.531-2.719L43.919,144.65z"
        />
        <path
          d="M35.813,136.672l-13.01,10.103l-4.789-6.168l2.934-2.276l2.35,3.026l2.013-1.562l-2.278-2.934l2.896-2.248l2.276,2.933
			l2.238-1.737l-2.395-3.083l2.934-2.277L35.813,136.672z"
        />
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts">
const props = defineProps<{
  class?: string
  rating: number
}>()
</script>
